/*****************************************************************************************
 * 설명 : 모달 표시
*****************************************************************************************/
import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';

import { PaperComponent } from 'service/utils';

import { hideDialog } from 'reducers/dialogReducer';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const AlertModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dialog = useSelector(state => state?.dialog);
  const dispatch = useDispatch();

  /***************************************************************************************
   * 설명 : html 리턴
  ***************************************************************************************/
  if( dialog?.isDialog ) {
    return (
      <Dialog
        open={dialog.isDialog}
        onClose={() => (event, reason) => {
          if(reason && reason === "backdropClick") return;

          dispatch(hideDialog());
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",  // Set your width here
              borderRadius: "12px"
            },
          },
        }}
      >
        <section className="home-modal" style={{width: '100%'}}>
          <section className="modal-wrap" style={{width: '100%'}}>
            <header className="modal-header" style={{ cursor: 'move', display: 'block !important' }} id="draggable-dialog-title">
              <div className="modal-title cblack" style={{display: 'block'}}>
                {dialog?.header}
              </div>
              {/* <Button className="close-btn" onClick={() => dispatch(hideDialog())}><CloseIcon></CloseIcon></Button> */}
            </header>

            <section className="modal-body flex flex-center" style={{minHeight: '100px'}}>
              <span className="message-dialog-content" dangerouslySetInnerHTML={{__html: dialog?.message}}></span>
            </section>

            <footer className="modal-footer">
              <div className="modal-footer-center">
                <Button
                  variant="contained"
                  color="primary"
                  className="Btn green ml10"
                  onClick={() => {
                    if( dialog.click !== undefined && dialog.click !== null ) {
                      dialog.click();
                    }

                    dispatch(hideDialog());
                  }}
                >확인</Button>
              </div>
            </footer>
          </section>
        </section>
      </Dialog>
    );
  } else {
    return (
      <></>
    )
  }
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AlertModal;